.home-presc-box {
  flex: 1;
  /* background-color: beige; */
  text-align: center;
}

.home-presc-box:not(:last-child) {
  margin-right: 15px;
}

.home-presc-link {
  margin: 10px;
}

.presc-option-img {
  /* background-color: pink; */
  /* height: 60%; */
  margin-top: 20px;
  margin-bottom: 5px;
  margin-left: 20px;
  margin-right: 20px;
}

.presc-image {
  object-fit: contain;
  /* margin: 25px; */
  width: 80%;
}

.presc-option-btn {
  flex: 1;
  background-color: rgba(98, 137, 166);
  color: var(--text-color-black);
  border: none;
  padding: 10px 3px;
  margin-bottom: 2%;
  font-size: 18px;
  font-weight: 700;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 8%;
  cursor: pointer;
  justify-content: center;
}

.presc-option-btn:hover {
  background-color: var(--bg-color-home-presc-btn-hover);
  color: var(--text-color-black);
}

@media screen and (max-width: 600px) {
  .home-presc-box:not(:last-child) {
    margin-right: 0px;
    margin-bottom: 20px;
  }
}
