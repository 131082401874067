.home-container {
  background-color: var(--bg-color);
  width: 100%;
  /* margin-top: 10px; */
  position: relative;
}

.images-options-container {
  background-color: var(--bg-color-light);
  height: 580px;
  align-items: center;
  object-fit: contain;
  width: 100%;
  padding-bottom: 30px;
}

.home-image {
  height: 600px;
  object-fit: cover;
  width: 100%;
}

.prescription-container {
  /* background-color: aqua; */
  position: absolute;
  width: 75%;
  /* height: 35%; */
  margin: 0 12%;
  top: 17%;
  /* bottom: 5%; */
  display: flex;
  flex-direction: row;
}

.presc-option1 {
  background-color: var(--bg-color-home-presc-option1);
  border: 3px solid white;
}

.presc-option2 {
  background-color: var(--bg-color-home-presc-option2);
  border: 3px solid white;
}

.presc-option3 {
  background-color: var(--bg-color-home-presc-option3);
  border: 3px solid white;
}

.mid1 {
  background-color: var(--bg-color-dark);
  margin: 3px 0;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 10px 0;
  color: var(--text-color-white);
}

.mid1-top {
  /* background-color: purple; */
  font-size: 34px;
  padding-bottom: 1%;
  font-weight: bold;
  font-family: serif;
}

.mid1-bottom {
  /* background-color: hotpink; */
  padding-bottom: 1%;
}
.mid1-bottom-one {
  font-size: 34px;
  font-weight: bold;
}

.mid1-bottom-two {
  font-size: large;
}

.mid2 {
  color: var(--text-color-dark);
  text-align: center;
  padding: 3% 0;
}

.mid2-hdr {
  font-size: 34px;
  text-align: center;
  /* background-color: hotpink; */
}

.mid2-content {
  /* background-color: aliceblue; */
  margin: 3% 10%;
  display: flex;
}

.mid2-info-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  /* background-color: lightcoral; */
  align-items: center;
  margin-top: 2px;
  margin-right: 1%;
  padding: 2%;
  padding-bottom: 10px;
}

.mid2-info-box:not(:last-child) {
  margin-right: 2%;
}

.mid2-icon-container {
  background-color: var(--text-color-dark);
  border: 0px var(--text-color-dark);
  border-radius: 100%;
  padding: 20px;
}

.mid2-icon {
  color: var(--text-color-white);
  font-size: 50px;
  align-self: start;
}

.mid2-info-title {
  font-size: 25px;
  margin-top: 10px;
  /* background-color: aqua; */
  height: 55px;
}

.mid2-info-container {
  /* background-color: aquamarine; */
  margin-top: 8px;
}

.mid2-info {
  /* background-color: beige; */
  color: var(--text-color-darker-grey);
}

.mid3 {
  background-color: var(--bg-color-home-mid3);
  padding: 2%;
  text-align: center;
  font-size: large;
}

.mid3-hdr {
  /* background-color:purple; */
  color: var(--text-color-dark);
  margin-bottom: 10px;
  font-size: xx-large;
}

.mid3-sign-up-btn {
  width: 70%;
  padding: 0.5% 0;
  font-size: x-large;
  background-color: var(--bg-color-signUp-btn);
  color: var(--text-color-white);
  border: 0px;
  margin-top: 1%;
}

.mid3-sign-up-btn:hover {
  background-color: var(--bg-color-signUp-btn-hover);
  color: var(--text-color-black);
}

@media only screen and (max-width: 600px) {
  .images-options-container {
    height: 970px;
  }

  .home-image {
    height: 970px;
  }

  .prescription-container {
    flex-direction: column;
    top: 20px;
  }
}

@media only screen and (max-width: 450px) {
  .images-options-container {
    height: 780px;
  }

  .home-image {
    height: 780px;
  }
}

@media only screen and (max-width: 800px) {
  .mid2-content {
    flex-direction: column;
    align-items: center;
  }

  .mid2-info-title {
    height: 40px;
    margin-top: 2%;
  }

  .mid2-info-box:not(:last-child) {
    margin-right: 0;
    margin-bottom: 5%;
  }
}

@media only screen and (max-width: 340px) {
  .mid2-info-title {
    height: 50px;
    margin-bottom: 7px;
  }
}
