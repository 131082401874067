.our-services-container {
  width: 100%;
  height: auto;
  /* background-color: aquamarine; */
  background-color: var(--bg-color-services);
}

.our-services-title-container {
  width: 100%;
  height: 90px;
  background-color: var(--bg-color-page-title-options);
  display: flex;
  align-items: center;
}

.our-services-title {
  margin-left: 11%;
  color: var(--text-color-white);
  font-size: 34px;
  /* background-color: aqua; */
}

.service-box-container {
  /* background-color: pink; */
  margin: 5% 11%;
  display: flex;
}

.service-box {
  flex: 1;
  flex-direction: column;
  /* padding: 10px; */
  /* background-color: aquamarine; */
  margin: 10px;
  /* width: 32%; */
  text-align: center;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.service-box-img-box {
  /* background-color: burlywood; */
  width: 100%;
}

.service-box-img-container {
  display: inline-block;
  position: relative;
  /* margin: 0 20%; */
  margin-top: 2%;
  width: 150px;
  height: 150px;
  overflow: hidden;
  border-radius: 50%;
  background-color: white;
}

.service-box-image {
  /* display: block; */
  border-radius: 50%;
  /* object-fit: contain; */
  width: 150px;
  height: 100%;
  color: transparent;
}

.service-box-rectangle-image {
  width: auto;
  height: 100%;
  margin-left: -180px;
}

.service-box-btn {
  text-align: center;
  margin-top: 2%;
  background-color: transparent;
  color: var(--text-color-dark);
  height: 50px;
  text-decoration-line: underline;
  font-size: 25px;
  border: none;
  cursor: pointer;
}

.service-box-intro {
  /* background-color: orchid; */
  margin: 0 5%;
  margin-top: 2%;
  font-size: 16px;
  line-height: 1.8em;
  text-align: center;
  font: normal normal normal questrial, sans-serif;
  color: var(--text-color-darker-grey);
}

@media screen and (max-width: 750px) {
  .service-box-container {
    flex-direction: column;
  }

  .service-box-img-container {
    margin: 0 20%;
  }

  .service-box-rectangle-img-container {
    margin: 0 20%;
  }
}

@media screen and (max-width: 500px) {
  .service-box-btn {
    height: 65px;
  }
}
