#root {
  --bg-color: rgb(255, 255, 255);
  --bg-color-dark: rgb(149, 182, 207);
  --bg-color-light: rgb(211, 231, 245);
  --bg-color-page-title-options: rgb(163, 200, 227);
  --bg-color-home-mid3: rgb(242, 242, 242);
  --bg-color-signUp-btn: rgba(117, 166, 201, 1);
  --bg-color-signUp-btn-hover: rgba(161, 201, 224);
  --bg-color-home-presc-option1: rgba(163, 200, 227, 0.9);
  --bg-color-home-presc-option2: rgba(149, 182, 207, 0.9);
  --bg-color-home-presc-option3: rgba(117, 166, 201, 0.9);
  --bg-color-home-presc-btn: rgba(98, 137, 166, 0.54);
  --bg-color-home-presc-btn-hover: rgba(191, 215, 230);
  --bg-color-booknow-btn-hover: rgb(93, 134, 160);
  --bg-color-services: rgba(232, 230, 230, 0.8);
  --bg-color-nav-btn: rgb(255, 60, 69);

  --text-color-white: rgb(255, 255, 255);
  --text-color-black: rgb(0, 0, 0);
  --text-color-dark: rgb(149, 182, 207);
  --text-color-light: #faece1;
  --text-color-grey: #645e5a;
  --text-color-light-grey: #c7c7c7;
  --text-color-darker-grey: #757575;

  --border-color-input: #645e5a;

  --header-two-right-text: #645e5a;
  --header-icon-color: rgb(149, 182, 207);

  --loading-spinner-border-color: #645e5a;
  --loading-spinner-border-top-color: rgb(149, 182, 207);
}
