.form-container {
  width: 100%;
  background-color: var(--bg-color);
  position: relative;
}

.forms-title {
  width: 100%;
  height: 90px;
  background-color: var(--bg-color-page-title-options);
  display: flex;
  align-items: center;
}

.forms-title-txt {
  margin-left: 11%;
  color: var(--text-color-white);
  font-size: 34px;
  font-weight: normal;
  /* background-color: aqua; */
}

.row-container {
  position: relative;
  /* background-color: blueviolet; */
  margin: 0 11%;
  padding-top: 4%;
  padding-bottom: 3%;
}

.row {
  /* background-color: hotpink; */
  display: flex;
}

.column {
  display: flex;
  flex-direction: column;
  margin: 1%;
  width: 49.99%;
  /* background-color: pink; */
}

.col-label {
  color: var(--text-color-black);
  font-size: medium;
  margin-left: 2%;
  padding: 1%;
}

.required::after {
  content: " *";
  color: var(--text-color-black);
}

.col-input {
  background-color: var(--bg-color-light);
  color: var(--text-color-black);
  border: 2px solid var(--bg-color-light);
  border-radius: 15px;
  box-shadow: 6px 6px var(--bg-color-dark);
  padding: 4%;
  padding-left: 2%;
  outline: none;
  margin-top: 1%;
  /* margin-left: 4%; */
  font-size: medium;
  box-sizing: border-box;
}

.col-input-date {
  font-size: medium;
  font-family: sans-serif;
}

.column-email {
  display: flex;
  flex-direction: column;
  width: 49.99%;
  margin-left: 24.99%;
}

.column-text-area {
  display: flex;
  flex-direction: column;
  margin: 1%;
  width: 98%;
  /* background-color: pink; */
}

.col-label-textarea {
  color: var(--text-color-black);
  font-size: medium;
  margin-left: 0.6%;
  padding: 1%;
}

.col-input-textarea {
  background-color: var(--bg-color-light);
  color: var(--text-color-black);
  border: 2px solid var(--bg-color-light);
  border-radius: 15px;
  font-family: sans-serif;
  box-shadow: 6px 6px var(--bg-color-dark);
  padding: 2%;
  padding-left: 1%;
  outline: none;
  /* margin-top: 1%; */
  /* margin-left: 2%; */
  margin-right: 2%;
  width: 100%;
  font-size: medium;
  box-sizing: border-box;
  resize: none;
}

.col-input:hover {
  border-color: var(--border-color-input);
  background-color: var(--bg-color);
}
.col-input-textarea:hover {
  border-color: var(--border-color-input);
  background-color: var(--bg-color);
}

.col-input:focus {
  border-color: var(--border-color-input);
  background-color: var(--bg-color);
}

.col-input-textarea:focus {
  border-color: var(--border-color-input);
  background-color: var(--bg-color);
}

::placeholder {
  color: var(--text-color-black);
}

.input-file {
  /* background-color: aqua; */
  cursor: pointer;
  width: 43%;
  margin-left: 0;
}

input[type="file"]::file-selector-button {
  background-color: var(--bg-color-light);
  color: var(--text-color-black);
  border: 2px solid var(--bg-color-light);
  border-radius: 15px;
  font-size: medium;
  /* margin-left: 4%; */
  padding: 2%;
  width: fit-content;
  cursor: pointer;
}

input[type="file"]::file-selector-button:hover {
  border-color: var(--border-color-input);
}

.submit-btn-row {
  align-content: center;
}
.submit-btn {
  background-color: var(--bg-color-dark);
  border: none;
  cursor: pointer !important;
  border-radius: 12px;
  padding: 2% 5%;
  margin-top: 4%;
  color: var(--text-color-black);
  font-size: medium;
  margin-left: 43%;
  transition: border-color 0.4s ease 0s, background-color 0.4s ease 0s;
}

.submit-btn:hover {
  background-color: var(--bg-color-light);
}

@media only screen and (max-width: 700px) {
  .row {
    flex-direction: column;
  }

  .column {
    width: 98%;
  }
  .col-input-date {
    width: 100%;
  }

  .column-email {
    width: 98%;
    margin-left: 1%;
  }

  .col-label-textarea {
    margin-left: 3%;
  }

  .col-input-textarea {
    /* margin-right: 2%; */
    width: 100%;
  }

  .submit-btn-row {
    /* background-color: pink; */
    margin-top: 4%;
  }
  .submit-btn {
    margin-left: 40%;
  }
}
