.prescriptions-container {
  width: 100%;
  background-color: var(--bg-color);
}

.presc-title {
  width: 100%;
  height: 90px;
  background-color: var(--bg-color-page-title-options);
  display: flex;
  align-items: center;
}

.presc-title-txt {
  margin-left: 11%;
  color: var(--text-color-white);
  font-size: 34px;
  /* background-color: aqua; */
}

.options-container {
  /* background-color: blueviolet; */
  margin: 5% 13%;
  display: flex;
}

@media only screen and (max-width: 700px) {
  .options-container {
    flex-direction: column;
  }
}
