.aboutus-container {
  width: 100%;
  background-color: var(--bg-color);
}

.aboutus-title {
  width: 100%;
  height: 90px;
  background-color: var(--bg-color-page-title-options);
  display: flex;
  align-items: center;
}

.aboutus-title-txt {
  margin-left: 11%;
  color: var(--text-color-white);
  font-size: 34px;
  /* background-color: aqua; */
}

.pharmacyinfo-container {
  /* width: 78%; */
  /* background-color: beige; */
  display: flex;
  flex-direction: column;
  margin: 4% 11%;
}

.pharmacy-image-container {
  background-color: var(--bg-color-light);
  width: 100%;
  /* margin-left: 1%; */
  object-fit: contain;
}

.pharmacy-img {
  /* min-width: 300px; */
  width: 100%;
  object-fit: contain;
}

.pharmacy-info {
  width: 100%;
  /* background-color: beige; */
}

.pharmacyinfo-title {
  color: var(--text-color-dark);
  /* background-color: pink; */
  font-size: xx-large;
  /* margin-top: 30px; */
  text-align: center;
  font: normal normal normal syne, sans-serif;
}

.info {
  color: var(--text-color-darker-grey);
  font: normal normal normal 15px/1.4em questrial, sans-serif;
}

@media only screen and (max-width: 550px) {
  .pharmacyinfo-container {
    margin: 2%;
    width: 96%;
  }
}
