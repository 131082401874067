.footer {
  margin-top: auto;
  padding-bottom: 5px;
  background-color: var(--bg-color-light);
}

.footer-one {
  margin: 0 10%;
  color: var(--text-color-black);
  font-size: 17px;
  /* background-color: lightcyan; */
  font-family: times new roman, times, serif;
}

.footer-two {
  margin: 0 10%;
  font-size: 18px;
  font-family: times new roman, times, serif;
  display: flex;
  align-items: center;
  /* background-color: lightcoral; */
}

.footer-loc-icon {
  color: var(--text-color-white);
  font-size: large;
  /* background-color: aqua; */
}

.address {
  color: var(--text-color-black);
  margin-left: 5px;
}

.footer-phone-icon {
  color: var(--text-color-white);
  font-size: medium;
  margin-left: 1%;
}

.phone-number {
  color: var(--text-color-black);
  margin-left: 5px;
}

.fax-number {
  color: var(--text-color-black);
  margin-left: 1%;
}

.facebook-icon {
  color: var(--text-color-white);
  /* background-color: lightgreen; */
  font-size: large;
  /* margin-left: 15%; */
  margin-right: 5%;
  padding: 2px;
  cursor: pointer;
}

@media only screen and (max-width: 900px) {
  .footer-two {
    flex-direction: column;
    align-items: center;
  }
  .footer-one {
    align-items: center;
    text-align: center;
  }
}
