.header-container {
  overflow: hidden;
  background-color: var(--bg-color-light);
  padding: 20px 10px;
  /* margin-top: 20px; */
  /* display: flex; */
}

.header-title {
  font-size: 36px;
  cursor: pointer;
  font: normal normal normal 32px/1.4em syne, sans-serif;
  margin-left: 10%;
  float: left;
  color: var(--text-color-black);
}

.header-title-link {
  text-decoration: none;
}

.header-phone-add {
  float: right;
  color: var(--text-color-black);
  font-family: "times new roman", times, serif;
  font-size: 18px;
  margin-right: 12%;
  text-align: left;
  /* background-color: hotpink; */
}

.header-phone {
  display: flex;
  /* background-color: aquamarine; */
}

.header-phone-fax {
  display: flex;
  margin-left: 5px;
  /* background-color: hotpink; */
}

.header-fax {
  /* background-color: pink; */
  margin-left: 10px;
}

.header-icon1 {
  color: var(--text-color-dark);
  padding-top: 3px;
  font-size: large;
  /* background-color: aqua; */
}

.header-address {
  display: flex;
  margin-top: 5px;
  /* background-color: aquamarine; */
}

.header-icon2 {
  color: var(--text-color-dark);
  padding-top: 1%;
  font-size: large;
}

.header-address-txt {
  /* background-color: lightcoral; */
  margin-left: 5px;
}
@media screen and (max-width: 1090px) {
  .header-container {
    float: none;
  }
  .header-title {
    float: none;
    display: inline-block;
    text-align: left;
    margin-left: 10%;
  }

  .header-phone-add {
    float: none;
    margin-left: 10%;
  }

  .header-phone-fax {
    display: flex;
    flex-direction: column;
  }

  .header-fax {
    margin-left: 1px;
  }
}
