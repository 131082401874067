.presc-box {
  flex: 1;
  /* background-color: beige; */
  text-align: center;
}

.presc-box:not(:last-child) {
  margin-right: 15px;
}

.presc-img-box {
  width: 100%;
  /* background-color: pink; */
}

.option-img {
  margin-bottom: 5px;
}

.image {
  width: 100%;
}

.option-btn {
  background-color: var(--bg-color-page-title-options);
  color: var(--text-color-white);
  border: none;
  padding: 8px 10px;
  font-size: x-large;
  font-family: Arial, Helvetica, sans-serif;
  width: 100%;
  margin-top: 4%;
  cursor: pointer;
  justify-content: center;
}

.option-btn:hover {
  background-color: var(--bg-color-light);
  color: var(--text-color-black);
}

@media screen and (max-width: 700px) {
  .presc-box:not(:last-child) {
    margin-right: 0px;
    margin-bottom: 20px;
  }
}
