.booknow-btn {
  background-color: var(--bg-color-dark);
  color: var(--text-color-white);
  position: fixed;
  right: 0px;
  bottom: 0px;
  /* width: 15%; */
  padding: 1% 4%;
  border: none;
  border-radius: 20px 20px 0 0;
  font-size: large;
  font-weight: bold;
  cursor: pointer;
}

.booknow-btn:hover {
  background-color: var(--bg-color-booknow-btn-hover);
}
