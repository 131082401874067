.dropdown-menu {
  position: absolute;
  top: 73%;
  list-style: none;
  margin-left: -2px;
  background-color: var(--bg-color);
  /* background-color: aquamarine; */
  z-index: 2;
}

.dropdown-menu li {
  cursor: pointer;
  display: block;
  position: relative;
  /* align-self: center; */
}

.dropdown-menu.clicked {
  display: none;
}

.dropdown-link {
  display: block;
  text-decoration: none;
  padding-top: 5px;
  text-decoration: none;
  color: var(--text-color-black);
  font-weight: 700;
  font-stretch: normal;
  font-size: 18px;
  text-align: left !important;
  /* background-color: pink; */
  /* border: 1px solid; */
}

.dropdown-link:hover {
  color: var(--text-color-dark);
  transition: color 0.4s ease 0s;
}

@media screen and (max-width: 1100px) {
  .dropdown-menu {
    display: none;
  }
}
