.message-box-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.8);
  text-align: center;
}

.message-box {
  background-color: var(--bg-color);
  position: absolute;
  top: calc(50% - 135px);
  left: calc(50% - 17.5%);
  right: 0;
  width: 35%;
  height: 270px;
  border: 0px solid var(--bg-color-dark);
  border-radius: 10px;
}

.message-box-header {
  font-size: 25px;
  padding: 15px;
  /* margin-top: 10px; */
  /* color: var(--text-color-dark); */
  background-color: var(--bg-color-dark);
  border-radius: 5px;
}

.message-box-msg {
  /* background-color: pink; */
  color: var(--text-color-grey);
  text-align: left;
  margin-top: 10px;
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 16px;
  height: auto;
}

.message-box-options {
  margin-top: auto;
  position: absolute;
  width: 100%;
  padding: 7px 0;
  background-color: var(--bg-color-dark);
  bottom: 0;
  border: 0px solid var(--bg-color-dark);
  border-radius: 5px;
}

.message-box-btn {
  background-color: var(--bg-color);
  font-size: 16px;
  float: right;
  margin: 3px 0;
  margin-right: 5px;
  padding: 7px 10px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

@media screen and (max-width: 915px) {
  .message-box {
    left: calc(50% - 75px);
    width: 150px;
  }

  .message-box-header {
    font-size: 20px;
  }
}
