@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  position: absolute;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
  right: 0;
  width: 50px;
  height: 50px;
  border: 10px solid var(--loading-spinner-border-color);
  border-top: 10px solid var(--loading-spinner-border-top-color);
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

.spinner-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.8);
}
