.navbar {
  /* background-color: aqua; */
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  /* align-content: center; */
  position: relative;
}

.navbar-items {
  /* background-color: blueviolet; */
  display: flex;
  margin-left: 11%;
  /* padding-left: 2%; */
  margin-right: 5px;
  padding: 2px 0;
  align-items: center;
}
.nav-item {
  /* background-color: lightcyan; */
  display: flex;
  align-items: center;
  margin-right: 7px;
}

.nav-link {
  text-decoration: none;
  color: var(--text-color-black);
  /* background-color: pink; */
  padding: 7px 2px;
  font-weight: 700;
  font-stretch: normal;
  font-size: 18px;
}

.nav-link-active {
  text-decoration: none;
  color: var(--text-color-dark);
  /* background-color: pink; */
  padding: 7px 2px;
  font-weight: 700;
  font-stretch: normal;
  font-size: 18px;
}

.nav-link:hover {
  color: var(--text-color-white);
  background-color: var(--bg-color-dark);
  /* transition: background-color 0.4s ease 0s; */
}

.nav-link-active:hover {
  color: var(--text-color-white);
  background-color: var(--bg-color-dark);
  /* transition: background-color 0.4s ease 0s; */
}

.btn {
  background-color: var(--bg-color-nav-btn);
  padding-top: 8px;
  padding-bottom: 5px;
  padding-left: 2px;
  padding-right: 2px;
  color: var(--text-color-white);
  font-size: 20px;
  border: none;
  cursor: pointer;
}

.btn:hover {
  background-color: var(--bg-color-dark);
}

.navbar-btn {
  display: none;
  position: absolute;
  background-color: var(--bg-color-dark);
  font-size: larger;
  text-align: center;
  font-weight: bold;
  color: var(--text-color-white);
  right: 10px;
  top: 3px;
  padding: 7px 10px;
}

@media screen and (max-width: 1100px) {
  .navbar {
    height: auto;
    min-height: 50px;
    flex-direction: column;
    display: block;
    /* text-align: center; */
    /* background-color: palegreen; */
  }

  .navbar-items {
    /* top: 60px; */
    width: 100%;
    margin: 0;
    margin-top: 5px;
    /* padding: 10px 0; */
    flex-direction: column;
    align-items: center;
    /* background-color: hotpink; */
  }

  .nav-item {
    width: 100%;
  }

  .nav-link {
    width: 100%;
    display: inline-block;
    text-align: center;
    /* border: 2px solid; */
    margin-top: 1px;
    margin-bottom: 1px;
    padding: 5px 0;
  }

  .nav-link-active {
    width: 100%;
    display: inline-block;
    text-align: center;
    /* border: 2px solid; */
    margin-top: 1px;
    margin-bottom: 1px;
    padding: 5px 0;
  }

  .btn {
    margin-top: 2px;
  }

  .navbar-btn {
    display: block;
  }

  .nav-item:hover {
    background-color: var(--bg-color-light);
  }
}
