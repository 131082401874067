.service-container {
  /* background-color: aquasmarine; */
  background-color: var(--bg-color-services);
}

.service-top-container {
  /* background-color: antiquewhite; */
  display: flex;
  margin: 0 13%;
  padding: 25px;
}

.service-img-container {
  /* background-color: peru; */
  margin-right: 3%;
  width: 100%;
  object-fit: cover;
}

.service-img {
  width: 100%;
  /* margin-top: 2%; */
  min-width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: contain;
}
/* 
.service-title-intro-container {
  background-color: aqua;
} */

.service-title {
  /* background-color: mediumorchid; */
  font-size: 35px;
  margin-top: 2%;
  color: var(--text-color-dark);
}

.service-intro {
  /* background-color: crimson; */
  margin-top: 5%;
  margin-left: 5px;
  font-size: 16px;
  color: var(--text-color-darker-grey);
}

.service-page-info {
  background-color: var(--bg-color);
  margin: 2% 8%;
  margin-top: 1%;
  padding: 2% 7%;
  white-space: pre-wrap;
  text-align: justify;
  color: var(--text-color-darker-grey);
  font: normal normal normal 15px/1.4em questrial, sans-serif;
}

@media screen and (max-width: 915px) {
  .service-top-container {
    flex-direction: column;
  }
}
