.contactus-container {
  width: 100%;
  background-color: var(--bg-color);
  position: relative;
}

.contactus-title {
  width: 100%;
  height: 90px;
  background-color: var(--bg-color-page-title-options);
  display: flex;
  align-items: center;
}

.contactus-title-txt {
  margin-left: 11%;
  color: var(--text-color-white);
  font-size: 34px;
  /* background-color: aqua; */
}

.content-container {
  position: relative;
  width: 100%;
  /* margin-left: 15%; */
  /* background-color: aqua; */
}

.loc-info-container {
  /* background-color: hotpink; */
  /* width: 33%; */
  margin: 0 11%;
}

.loc-info-title {
  /* background-color: aquamarine; */
  font-size: xx-large;
  color: var(--text-color-dark);
}

.loc-info-address {
  display: flex;
  font-size: 15px;
  color: var(--text-color-grey);
  /* background-color: burlywood; */
}

.location-icon {
  /* background-color: pink; */
  text-decoration: none;
  margin-top: 10px;
  padding: 3px;
  padding-left: 0;
  font-size: 35px;
  color: var(--text-color-grey);
}

.loc-info {
  color: var(--text-color-grey);
  font-size: 15px;
  /* background-color: burlywood; */
}

.contact-form-container {
  position: relative;
  width: 35%;
  /* background-color: hotpink; */
  margin-left: 11%;
  color: var(--text-color-grey);
  font-size: medium;
}

.contact-form-row {
  width: 95%;
  display: flex;
  flex-direction: column;
  /* background-color: lightcoral; */
}

.contact-form-col {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.column-label {
  margin: 2%;
}

.contact-required::after {
  content: " *";
  color: var(--text-color-dark);
}

.column-input {
  width: 95.5%;
  padding: 2%;
  font-size: 16px;
  font-family: sans-serif;
  color: var(--text-color-grey);
  outline: none;
  border: 1px solid var(--text-color-grey);
}

.column-textarea {
  width: 95.5%;
  padding: 2%;
  resize: none;
  font-size: 16px;
  font-family: sans-serif;
  color: var(---text-color-grey);
  outline: none;
  height: 80px;
  border: 1px solid var(--text-color-grey);
}

.column-input:hover {
  border-color: var(--bg-color-dark);
}

.column-input:focus {
  border-color: var(--bg-color-dark);
}

.column-textarea:hover {
  border-color: var(--bg-color-dark);
}

.column-textarea:focus {
  border-color: var(--bg-color-dark);
}

.submit-button {
  background-color: var(--bg-color-dark);
  width: 100%;
  border: none;
  margin-top: 4%;
  margin-bottom: 4%;
  padding: 2%;
  font-size: 18px;
  color: var(--text-color-white);
}

.submit-button:hover {
  background-color: var(--bg-color-page-title-options);
}

@media only screen and (max-width: 950px) {
  .loc-info-container {
    margin-left: 12%;
  }
  .contact-form-container {
    width: 80%;
    margin-left: 12%;
  }
}
